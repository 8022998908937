<template>
  <div class="top_title">
    <div>
      <el-radio v-model="radio" label="1">已认证</el-radio>
      <el-radio v-model="radio" label="2">未认证</el-radio>
    </div>
    <div class="top_input clearfix">
      <h6>发票代码</h6>
      <el-input style="width:50%;"  size="small"></el-input>
    </div>
    <div class="bottom_time clearfix">
      <div class="top_input2 clearfix fl">
        <h6>发票号</h6>
        <el-input style="width:50%;"  size="small"></el-input>
      </div>
      <div class="top_input2 clearfix fr">
        <h6>开票日期</h6>
        <el-date-picker type="date" placeholder="选择日期时间" size="small"></el-date-picker>
      </div>
    </div>
  </div>
 
  <table class="contents" cellspacing="0">
    <tr colspan="12">
      <td style="width:20%">机打代码</td>
      <td colspan="2" style="background-color:whiteSmoke"></td>
      <td rowspan="3">税控区</td>
      <td colspan="4" rowspan="3" style="background-color:whiteSmoke"></td>
    </tr>
    <tr colspan="8">
      <td>机打号码</td>
      <td colspan="2" style="background-color:whiteSmoke"></td>
    </tr>
    <tr colspan="8">
      <td>机器编号</td>
      <td colspan="2">3</td>
    </tr>
    <tr colspan="8">
      <td><span class='star'>*</span>购买方名称及身份证</td>
      <td colspan="3">4</td>
      <td>纳税人识别号</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>车辆类型</td>
      <td>5</td>
      <td><span class='star'>*</span>厂牌型号</td>
      <td>5</td>
      <td><span class='star'>*</span>产地</td>
      <td colspan="3">5</td>
    </tr>
    <tr colspan="8">
      <td>合格证号</td>
      <td>6</td>
      <td>进口证明书号</td>
      <td>6</td>
      <td>商检单号</td>
      <td colspan="3">6</td>
    </tr>
    <tr colspan="8">
      <td>发动机号码</td>
      <td colspan="3">4</td>
      <td>车牌识别号/车架号码</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>价税合计（大写）</td>
      <td colspan="3">4</td>
      <td>小写</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td><span class='star'>*</span>销货单位名称</td>
      <td colspan="3">4</td>
      <td>电话</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>纳税人识别号</td>
      <td colspan="3">4</td>
      <td>账号</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>地址</td>
      <td colspan="3">4</td>
      <td>开户银行</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td><span class='star'>*</span>业务类型</td>
      <td colspan="3">4</td>
      <td>备注</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td><span class='star'>*</span>增值税税率或征收率</td>
      <td>5</td>
      <td>增值税税额</td>
      <td>5</td>
      <td>主管税务机关及代码</td>
      <td colspan="3">5</td>
    </tr>
    <tr colspan="8">
      <td><span class='star'>*</span>不含税价</td>
      <td>5</td>
      <td>完税凭证号码</td>
      <td>5</td>
      <td>吨位</td>
      <td>5</td>
      <td>限乘人数</td>
      <td>5</td>
    </tr>


  </table>

  <div class="bottom_num">
    发票张数
    <el-input style="width:100px" size="small" />
    <div style="display:inline-block;margin-left:20px;margin-right:20px" v-if="radio == '1'">
      <span><span class='star'>*</span>抵扣状态：</span>
      <el-radio v-model="radio" label="1">本期抵扣</el-radio>
      <el-radio v-model="radio" label="2">不予抵扣</el-radio>
    </div>
    <div style="display:inline-block" v-if="radio == '1'">
      <span><span class='star'>*</span>抵扣方式：</span>
      <el-radio v-model="radio" label="1">一般项目</el-radio>
      <el-radio v-model="radio" label="2">即征即退</el-radio>
    </div>
    <div style="display:inline-block;margin-left:10px" v-if="radio == '1'">
      <span>进项税转出类型：</span>
      <el-select size="small"></el-select>
      <span>转出金额：</span>
    </div>
  </div>
</template>

<script>
export default {
  name:'jdcxstyfp',
  data() {
    return {
      radio:'1'
    }
  }
}
</script>

<style lang="scss" scoped>
.contents{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  } 
  input{
    width: 100%;
    line-height: 25px;
    padding: 0 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 12px;
  }
}
.top_title {
  width: 100%;
  margin: 0 auto;
  h5 {
    font-size: 30px;
    color: #333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 36px;
  }
}
.top_input {
  width: 30%;
  h6 {
    float: left;
    width: 60px;
    font-size: 14px;
    color: #333;
    font-weight: normal;
    line-height: 36px;
    margin-right: 10px;
  }
}
.top_input2 {
  width: 30%;
  margin: 0 auto;
  position: relative;
  h6 {
    float: left;
    width: 60px;
    font-size: 14px;
    color: #333;
    font-weight: normal;
    line-height: 28px;
    margin-right: 10px;
  }
  input {
    float: left;
    width: 47%;
    border: none;
    border-bottom: 1px solid #666;
    margin-left: 5px;
    line-height: 22px;
  }
}
.bottom_num {
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
}
.star {
  color: #f15a24;
  height: 12px;
  font-size: 18px;
  display: inline-block;
  z-index: 999;
}
</style>