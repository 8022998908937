<template>
  <el-form ref="form" :model="form" label-width="260px" size="small" inline>
    <el-form-item label="发票代码">
      <el-input size="small" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="发票号码">
      <el-input size="small" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="机打号码">
      <el-input size="small" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="销售方名称">
      <el-select size="small" v-model="form.region" placeholder="请选择销售方名称">
        <el-option label="区域一" value="shanghai"></el-option>
        <el-option label="区域二" value="beijing"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="备注">
      <el-input size="small" v-model="form.name" placeholder="输入凭证摘要"></el-input>
    </el-form-item>
    <el-form-item label="开票日期">
      <el-date-picker
        type="date"
        placeholder="选择日期"
        size="small" v-model="form.date1"
        style="width: 100%;"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="外币核算">
      <el-input size="small" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="购买方名称">
      <el-input size="small" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="机器编号">
      <el-input size="small" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="纳税人识别号">
      <el-input size="small" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="收款员">
      <el-input size="small" v-model="form.name"></el-input>
    </el-form-item>
  </el-form>
  <div class="jpStyle">
    <div class="item_box">
      <el-row :span="24">
        <el-col :xs="7" :sm="7" :lg="7" style="border-left:1px solid #008000">
        <div class="grid-content bg-purple">项目类型
            <span class='star'>*</span>
        </div>
        </el-col>
        <el-col :xs="5" :sm="5" :lg="5">
        <div class="grid-content bg-purple">项目
        </div>
        </el-col>
        <el-col :xs="4" :sm="4" :lg="4">
        <div class="grid-content bg-purple">数量</div>
        </el-col>
        <el-col :xs="4" :sm="4" :lg="4">
        <div class="grid-content bg-purple">单价</div>
        </el-col>
        <el-col :xs="4" :sm="4" :lg="4">
          <div class="grid-content bg-purple">金额
            <span class='star'>*</span>
          </div>
        </el-col>
      </el-row>
    </div>
      <!-- 标题栏 end -->
      <!-- items -->
    <div class="item_box border_none">
      <el-row :span="24">
        <!-- 业务类型 (科目)-->
        <el-col :xs="7" :sm="7" :lg="7" style="border-left:1px solid #008000">
          <div class="grid-content">
          </div>
        </el-col>
        <el-col :xs="5" :sm="5" :lg="5">
          <div class="grid-content">
          </div>
        </el-col>
        <!-- 规格型号 -->
        <el-col :xs="4" :sm="4" :lg="4">
          <div class="grid-content">
            <input type="text">
          </div>
        </el-col>
        <!-- 单位 -->
        <el-col :xs="4" :sm="4" :lg="4">
          <div class="grid-content">
            <input type="text">
          </div>
        </el-col>
        <!-- 数量 -->
        <el-col :xs="4" :sm="4" :lg="4">
          <div class="grid-content">
            <input type="text">
          </div>
        </el-col>
      </el-row>
    </div>
      <!-- 输入信息栏 end -->
      <!-- 共计 -->
    <div class="total">
      <el-row :span="24">
        <el-col :xs="7" :sm="7" :lg="7" style="border-left:1px solid #008000">
          <div class="grid-content bg-purple">价税合计(大写)</div>
        </el-col>
        <el-col :xs="5" :sm="5" :lg="5">
          sumTotal
        </el-col>
        <el-col :xs="7" :sm="7" :lg="7">
          <div class="grid-content bg-purple">小写</div>
        </el-col>
        <el-col :xs="5" :sm="5" :lg="5" style="height:100%;;text-align:center;">
          <input type="text" >
        </el-col>
      </el-row>
    </div>
  </div>
  <div class="bottom_num">
    发票张数
    <el-input style="width:100px" size="small" />
  </div>
</template>

<script>
export default {
  name:'zzsptfpjp',
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.jpStyle{
  .item_box {
    width: 68%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .close {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      right: -32px;
      top: 9px;
      font-size: 20px;
      color: #ff1818;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .add {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      left: -28px;
      top: 9px;
      font-size: 16px;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    input {
      width: 100%;
      margin: 0 auto;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      color: #333;
      background: none;
      border: none;
      padding: 0 5px;
    }
  }
  .total{
    width: 68%;
  }
}
  .top_title {
    width: 100%;
    margin: 0 auto;
    h5 {
      font-size: 30px;
      color: #333;
      text-align: center;
      font-weight: 500;
      margin-bottom: 36px;
    }
  }
  .top_input {
    width: 30%;
    h6 {
      float: left;
      width: 60px;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
    }
  }
  .top_input2 {
    width: 30%;
    margin: 0 auto;
    position: relative;
    h6 {
      float: left;
      width: 60px;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      line-height: 28px;
      margin-right: 10px;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .content {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #008000;
    margin-top: 10px;
    border-right: none;
  }
  .grid-content {
    position: relative;
    min-height: 38px;
    font-size: 14px;
    color: #666;
    line-height: 38px;
    text-align: center;
  }
  .row_box2 {
    width: 100%;
    height: 154px;
  }
  .height_font {
    width: 100%;
    height: 152px;
    margin: 0 auto;
    text-align: center;
    p {
      width: 20px;
      margin: 0 auto;
      height: 100%;
      line-height: 28px;
      padding-top: 22px;
    }
  }
  .list_info {
    line-height: 24px;
    &.red li {
      border-bottom: 1px solid #d56400 !important;
    }
    li {
      position: relative;
      line-height: 38px;
      height: 38px;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid #008000;

      &:last-child {
        border-bottom: none;
      }
      input {
        width: 100%;
        margin: 0 auto;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #333;
        background: none;
        border: none;
        padding: 0 5px;
      }
    }
  }
  .weight {
    font-weight: 700;
  }
  .weight {
    li {
      font-weight: 600 !important;
    }
  }
  .star {
    color: #f15a24;
    height: 12px;
    font-size: 18px;
    display: inline-block;
    z-index: 999;
  }
  .input_box {
    width: 100%;
    height: 152px;
    font-size: 14px;
    color: #666;
    // line-height: 38px;
    text-align: center;
  }
  .top_font {
    border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 39px;
    font-size: 14px;
    color: #333;
  }
  .item_box {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .close {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      right: -32px;
      top: 9px;
      font-size: 20px;
      color: #ff1818;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .add {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      left: -28px;
      top: 9px;
      font-size: 16px;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    input {
      width: 100%;
      margin: 0 auto;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      color: #333;
      background: none;
      border: none;
      padding: 0 5px;
    }
  }
  .item_list {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .add {
      position: absolute;
      right: -38px;
      top: 10px;
      width: 1.8em;
      height: 1.8em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .bg-purple {
      line-height: 38px;
      height: 38px;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid #008000;
      // position: relative;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .bg-purple {
    text-align: center;
  }
  .bottom_num {
    width: 20%;
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    font-weight: normal;
    line-height: 36px;
  }
  .total {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 38px;
    font-size: 14px;
    color: #333;
  }
 .el-row {
  width: 100%;
  }
 .el-col {
  border-right: 1px solid #008000;
  }
  .special h5 {
    color: #d56400;
    border-bottom: 4px double #d56400;
    span {
      border-bottom: 16px solid #d56400;
    }
  }
  .special .el-col {
    border-right: 1px solid #d56400;
  }
  .special .content {
    border: 1px solid #d56400;
    border-right: none;
  }
  .special .list_info li {
    border-bottom: 1px solid #d56400;
    &:last-child {
      border-bottom: none;
    }
  }
  .special .textarea {
    border-bottom: 1px solid #d56400;
  }
  .special .top_font {
    border-bottom: 1px solid #d56400;
  }
  .special .item_list {
    border-top: 1px solid #d56400;
  }
  .special .item_box {
    border-top: 1px solid #d56400;
  }
  .special .total {
    border-top: 1px solid #f15a24;
  }
</style>