<template>
  <el-select :class="{border_none:borderNone}" @change="changeSsflbm" filterable v-model="ssflbm" placeholder="选择税收分类" size="small">
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
export default {
  props:{
    ssflbm: {
      type: String,
      default: ""
    },
    all: {
      type: Boolean,
      default: false
    },
    borderNone:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      options:[
        {
          name: '货物',
          value: "10",
        },
        {
          name: '劳务',
          value: "20",
        },
        {
          name: '销售服务',
          value: "30",
        },
        {
          name: '无形资产',
          value: "40",
        },
        {
          name: '不动产',
          value: "50",
        },
        {
          name: '未发生销售行为的不征税项目',
          value: "60",
        },
      ],
    }
  },
  created () {
    if(this.all){
      this.options.unshift({
        name: '全部',
          value: "",
      })
    }
  },
  methods: {
    changeSsflbm(){
      this.$emit("update:ssflbm", this.ssflbm)
      this.$emit("success", this.ssflbm)
    }
  }
}
</script>