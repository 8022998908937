<template>
  <div class="top_title">
    <div class="top_input clearfix">
      <h6>发票代码</h6>
      <el-input style="width:50%;"  size="small"></el-input>
    </div>
    <div class="bottom_time clearfix">
      <div class="top_input2 clearfix fl">
        <h6>发票号</h6>
        <el-input style="width:50%;"  size="small"></el-input>
      </div>
      <div class="top_input2 clearfix fr">
        <h6>开票日期</h6>
        <el-date-picker type="date" placeholder="选择日期时间" size="small"></el-date-picker>
      </div>
    </div>
  </div>
 
  <table class="contents" cellspacing="0">
    <tr colspan="12">
      <td style="width:20%">机打代码</td>
      <td colspan="2">1</td>
      <td rowspan="3">税控区</td>
      <td colspan="4" rowspan="3"></td>
    </tr>
    <tr colspan="8">
      <td>机打号码</td>
      <td colspan="2">2</td>
    </tr>
    <tr colspan="8">
      <td>机器编号</td>
      <td colspan="2">3</td>
    </tr>
    <tr colspan="8">
      <td>买方单位/个人<span class="star">*</span></td>
      <td colspan="3">4</td>
      <td>单位代码/身份证号码</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>买方单位/个人住址</td>
      <td colspan="3">4</td>
      <td>电话</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>卖方单位/个人<span class="star">*</span></td>
      <td colspan="3">4</td>
      <td>单位代码/身份证号码</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>卖方单位/个人住址</td>
      <td colspan="3">4</td>
      <td>电话</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>业务类型<span class="star">*</span></td>
      <td colspan="3">4</td>
      <td>备注</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>车辆牌照</td>
      <td>5</td>
      <td>登记证号</td>
      <td>5</td>
      <td>车辆类型</td>
      <td colspan="3">5</td>
    </tr>
    <tr colspan="8">
      <td>车辆识别号/车架号</td>
      <td>6</td>
      <td>厂牌型号<span class="star">*</span></td>
      <td>6</td>
      <td>转入地车辆管理名称</td>
      <td colspan="3">6</td>
    </tr>
    <tr colspan="8">
      <td>车价合计（大写）</td>
      <td colspan="3">4</td>
      <td>小写<span class="star">*</span></td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>经营拍卖单位</td>
      <td colspan="7">4</td>
    </tr>
    <tr colspan="8">
      <td>经营拍卖单位地址</td>
      <td colspan="3">4</td>
      <td>纳税人识别号</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td>开户银行、账号</td>
      <td colspan="3">4</td>
      <td>电话</td>
      <td colspan="3">4</td>
    </tr>
    <tr colspan="8">
      <td rowspan="2">二手市场</td>
      <td colspan="3" rowspan="2">4</td>
      <td>纳税人识别号</td>
      <td colspan="3"></td>
    </tr>
    <tr colspan="8">
      <td>地址</td>
      <td colspan="3"></td>
    </tr>
    <tr colspan="8">
      <td>开户银行、账号</td>
      <td colspan="3"></td>
      <td>电话</td>
      <td></td>
    </tr>
  </table>

  <div class="bottom_num">
    发票张数
    <el-input style="width:100px" size="small" />
  </div>
</template>

<script>
export default {
  name:'escxstyfp '
}
</script>

<style lang="scss" scoped>
.contents{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  } 
  input{
    width: 100%;
    line-height: 25px;
    padding: 0 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 12px;
  }
}
.top_title {
  width: 100%;
  margin: 0 auto;
  h5 {
    font-size: 30px;
    color: #333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 36px;
  }
}
.top_input {
  width: 30%;
  h6 {
    float: left;
    width: 60px;
    font-size: 14px;
    color: #333;
    font-weight: normal;
    line-height: 36px;
    margin-right: 10px;
  }
}
.top_input2 {
  width: 30%;
  margin: 0 auto;
  position: relative;
  h6 {
    float: left;
    width: 60px;
    font-size: 14px;
    color: #333;
    font-weight: normal;
    line-height: 28px;
    margin-right: 10px;
  }
  input {
    float: left;
    width: 47%;
    border: none;
    border-bottom: 1px solid #666;
    margin-left: 5px;
    line-height: 22px;
  }
}
.bottom_num {
  width: 20%;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
}
.star {
  color: #f15a24;
  height: 12px;
  font-size: 18px;
  display: inline-block;
  z-index: 999;
}
</style>